import "../../style/index.css";
import "../../style/Connect/connectMiddle.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faBookOpen, faChalkboardUser, faUser } from '@fortawesome/free-solid-svg-icons';

const connectMiddle = ({ textSource }) => {
  return (

    <div className="container-out">
      <div class="container-xl container-pos">
        <div class="container">
          <div class="row gx-5 gy-5 borde justify-content-md-center">

            <div class="col borde col-xs-1" align="center">

              <div class="card col-sm-10 card1">
                <div class="card-body ">
                  <div className="box">
                    <FontAwesomeIcon className='cardIconC' icon={faBookOpen} size="4x" />
                  </div>
                  <div class=" card-title title-props-card"> {textSource.title1}</div>
                  <div className="linea"></div>
                  <p class=" borde text-color-card">{textSource.text1}</p>
                </div>
              </div>
            </div>

            <div class="col borde col-xs-1" align="center">
              <div class="card  col-sm-10 card1">
                <div class="card-body ">
                  <div className="box">
                    <FontAwesomeIcon className='cardIconC' icon={faChalkboardUser} size="4x" />
                  </div>
                  <h5 class="card-title title-props-card"> {textSource.title2}</h5>
                  <div className="linea"></div>
                  <p class="  borde text-color-card">{textSource.text2}</p>
                </div>
              </div>
            </div>

            <div class="col borde col-xs-1" align="center">
              <div class="card col-sm-10 card1">
                <div class="card-body ">
                  <div className="box a">
                    <FontAwesomeIcon className='cardIconC a' icon={faGlobe} size="4x" />
                  </div>
                  <h5 class="card-title title-props-card"> {textSource.title3}</h5>
                  <div className="linea"></div>
                  <p class="   text-color-card">{textSource.text3}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col borde col-xs-1 bigcard-marg" align="center">
          <div class="card  col-sm-11 card-big bigcard-marg">
            <div class="card-body ">
              <div class="row align-items-center">
                <div class="col-sm-7">
                  <div className="bg-txt-prop borde">
                    {textSource.text4}
                  </div>
                </div>
                <div class="col-sm-5 borde">
                  <div className=" bg-img-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default connectMiddle;