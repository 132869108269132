import "../../style/index.css";
import "../../style/Prime/primeIcons.css";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faLaptop, faPaintBrush, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from "react";



const primeFirst = ({ textSource}) => {  
    return (
     
        <div>
            <div class="container-sm bord">
                <div class="row bord justify-content-md-center justify-content-center">

                    <div class="bord col-sm-12 align-self-center" >
                        <div className="txtTitle">
                            <h2><strong>{textSource.title}</strong> </h2>
                        </div>

                    </div>

                    <div className="bord col-sm-3">
                        <div className="">
                            <div className="   justify-content-md-center align-self-center bord icon-fondo">
                                <FontAwesomeIcon className="icon-ancho-build cuadrado " icon={faBuilding} size="2x" />
                            </div>
                        </div>
                        <div className="colorTxtPr">
                            {textSource.icon1}
                        </div>
                    </div>

                    <div class="bord col-sm-3 ">
                        <div className="   bord icon-fondo">
                            <FontAwesomeIcon className="icon-ancho cuadrado ic-details" icon={faLaptop} size="2x" />
                        </div>
                        <div className="colorTxtPr">
                            {textSource.icon2}
                        </div>
                    </div>

                    <div class="bord col-sm-3 ">
                        <div className="">                            
                            <div className=" col justify-content-md-center align-self-center bord icon-fondo">
                                <FontAwesomeIcon className="icon-ancho cuadrado " icon={faPaintBrush} size="1x" />
                            </div>
                        </div>
                        <div className="colorTxtPr">
                            {textSource.icon3}
                        </div>
                    </div>

                    <div class="bord col-sm-3 align-self-cnter">
                        <div className="cudrado">
                            <div className=" col jstify-content-md-start align-self-left bord icon-fondo">
                                <FontAwesomeIcon className=" icon-ancho cuadrado " icon={faCreditCard} size="1x" />
                            </div>
                        </div>
                        <div className="colorTxtPr">
                            {textSource.icon4}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default primeFirst;