
import Layout from '../components/layout';
import PoliticsPr from '../components/Politics/politicsPr';
import { useTranslation } from 'react-i18next';

const Politics = () => {
  const [t, i18n] = useTranslation("politics")
    return (
          <Layout>            
            <PoliticsPr textSource={t("politics.all", { returnObjects: true })}/>
          </Layout>
        )
    }
    
export default Politics