import "../../style/index.css";
import "../../style/Contact/contactPage.css";

import eventos from '../../resources/Images/INICIO/eventos.jpg'


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ContactPage = ({ textSource, textSourceB }) => {
    return (

        <div className="ev-img" style={{ backgroundImage: `url(${eventos})` }}>

            <Container fluid className="borde gap-3 ">
                <Row className="justify-content-center container-mrg borde pos-container gap-3">
                    <Col className="col-md-3 col-8 borde props-col-left">
                        <section>                    
                            <div className="title"><header>{textSource.title}</header></div>
                            <div className="line"></div>
                            <div className="txtC">{textSource.subTitle}</div>
                            <div className="txtP">{textSource.p}</div>
                        </section>
                    </Col>

                    <Col className="col-md-5 col-8 borde props-col-right">
                        <form className="row g-3 needs-validation" noValidate>

                            <div className="col-md-4 col-12 position-relative">
                                <label htmlFor="validationTooltip01" className="form-label secondary colorTx">{textSourceB.name}</label>
                                <input type="text" className="form-control fieldF" id="validationTooltip01" required></input>
                                <div className="valid-tooltip">
                                    Looks good!
                                </div>
                            </div>

                            <div className="col-md-4 col-12 position-relative">
                                <label htmlFor="validationTooltip02" className="form-label colorTx">{textSourceB.lastname}</label>
                                <input type="text" className="form-control fieldF" id="validationTooltip02" required></input>
                                <div className="valid-tooltip">
                                    Looks good!
                                </div>
                            </div>

                            <div className="col-md-4 col-12 position-relative">
                                <label htmlFor="validationTooltipUsername" className="form-label colorTx">{textSourceB.mail}</label>
                                <div className="input-group has-validation">
                                    <span className="input-group-text" id="validationTooltipUsernamePrepend">@</span>
                                    <input type="email" className="form-control fieldF" id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" required></input>
                                    <div className="invalid-tooltip">
                                        Please choose a unique and valid email.
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 position-relative">
                                <label htmlFor="validationTooltip03" className="form-label colorTx">{textSourceB.phone}</label>
                                <input type="tel" className="form-control fieldF" id="validationTooltip03" required></input>
                                <div className="invalid-tooltip">
                                    Please provide a valid phone number.
                                </div>
                            </div>

                            <div className="col-md-4 col-12 position-relative">
                                <label htmlFor="validationTooltip04" className="form-label colorTx">{textSourceB.company}</label>
                                <input type="text" className="form-control fieldF" id="validationTooltip04" required></input>
                                <div className="invalid-tooltip">
                                    Please provide a valid company name.
                                </div>
                            </div>
                            <div class="col-md-4 position-relative">
                                <label for="validationTooltip02" className="form-label colorTx">{textSourceB.act}</label>
                                <input type="text" class="form-control fieldF" id="validationTooltip02" value="" required></input>
                                <div class="valid-tooltip">
                                    Please provide information.
                                </div>
                            </div>

                            <div class="col-md-6 position-relative">
                                <label for="validationTooltip02" className="form-label colorTx">{textSourceB.eventName}</label>
                                <input type="text" class="form-control fieldF" id="validationTooltip02" value="" required></input>
                                <div class="valid-tooltip">
                                    Looks good!
                                </div>
                            </div>
                            <div class="col-md-6 position-relative">
                                <label for="validationTooltip02" className="form-label colorTx">{textSourceB.placeEvent}</label>
                                <input type="text" class="form-control fieldF" id="validationTooltip02" value="" required></input>
                                <div class="valid-tooltip">
                                    Looks good!
                                </div>
                            </div>
                            <div class="col-md-6 position-relative">
                                <label for="validationTooltip03" class="form-label colorTx">{textSourceB.assitNum}</label>
                                <input type="text" class="form-control fieldF" id="validationTooltip03" required></input>
                                <div class="invalid-tooltip">
                                    Please provide a valid city.
                                </div>
                            </div>
                            <div class="col-md-3 position-relative">
                                <label for="validationTooltip03" class="form-label colorTx">{textSourceB.dateStart}</label>
                                <input type="text" class="form-control fieldF" id="validationTooltip03" required></input>
                                <div class="invalid-tooltip">
                                    Please provide a valid city.
                                </div>
                            </div>
                            <div class="col-md-3 position-relative">
                                <label for="validationTooltip03" class="form-label colorTx">{textSourceB.dateFinish}</label>
                                <input type="text" class="form-control fieldF" id="validationTooltip03" required></input>
                                <div class="invalid-tooltip">
                                    Please provide a valid city.
                                </div>
                            </div>
                            <div class="col-md-12 position-relative">
                                <label for="validationTooltip04" class="form-label colorTx">{textSourceB.servType}</label>
                                <select class="form-select fieldF" id="validationTooltip04" required>
                                    <option selected disabled value="">Choose...</option>
                                    <option>...</option>
                                </select>
                                <div class="invalid-tooltip">
                                    Please select a valid state.
                                </div>
                            </div>
                            <div class="col-md-12 position-relative">
                                <label for="validationTooltip05" class="form-label colorTx">{textSourceB.extraNotes}</label>
                                <input type="text" class="form-control fieldF" id="validationTooltip05" required></input>
                                <div class="invalid-tooltip">
                                    Please provide a valid zip.
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btnF" type="submit">{textSourceB.button}</button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>

        </div>

    )
}

export default ContactPage;