import React from 'react'
import Layout from '../components/layout'
import "../style/Politics/politicsPr.css";
import "../style/index.css"
import { Col, Row } from 'reactstrap';

const TermsConditions = () => {
    return (
        <Layout>
            <Row className='justify-content-center'>
                <Col className="contentAll "></Col>
                <div className="titleForm">
                    TÉRMINOS Y CONDICIONES DE LA COMPRAVENTA DE BOLETOS
                </div>

                <Col xs={9}>
                    <p className='tac-p'>
                        La aceptación de estos términos y condiciones constituye un Contrato de Compraventa entre Full Pass, y la persona que está comprando boletos. <br />
                        Autorización. <br /><br />

                        La compra a través de sistema de Full Pass está sujeta a la comprobación de los datos personales y de la tarjeta proporcionados, así como a la autorización por parte del banco emisor de la tarjeta de crédito o débito cuyos datos has proporcionado para el pago de los boletos solicitados y por parte del banco aceptante. <br /><br />

                        Si los datos personales o de la tarjeta de crédito proporcionados no coinciden con los datos a disposición del banco emisor de la tarjeta de crédito o débito o, aun coincidiendo los datos en cuestión, el banco emisor o el banco aceptante no autorizan el cargo solicitado, la compra no será procesada ni finalizada y los boletos serán ofrecidos para venta al público sin responsabilidad alguna para Full Pass. <br /><br />

                        Límite de Boletos. El número de boletos adquiribles por una misma persona está limitado. Si en virtud de que una o más operaciones de compra exceda el límite de boletos adquiribles por una misma persona, las solicitudes de compra serán canceladas sin previo aviso y sin responsabilidad para Full Pass. <br /><br />

                        En el caso que se hubiere hecho algún cargo a la tarjeta de crédito o débito por las compras que se cancelen por los supuestos previstos en el parrafo anterior, se reembolsará la totalidad de los cargos realizados y de acuerdo al tiempo e instrucciones establecidas por las instituciones bancarias. Lugares Disponibles. <br /><br />

                        Full Pass te proporcionara el mejor lugar disponible al momento de realizar la compra, de acuerdo al precio solicitado. Cargos por Servicio. <br /><br />

                        La compra de boletos a través de Full Pass generará un cargo por servicio por cada boleto o acceso y un cargo por entrega de boletos o por envío de acceso, según corresponda, por cada transacción, los cuales pueden variar dependiendo del tipo de entrega seleccionado y autorizado por Full Pass, todo lo anterior en adición al valor nominal del boleto o acceso. Los cargos por servicio, por entrega de boleto y/o por envío de acceso, aplicarán para cada boleto adquirido a través de Full Pass y no son reembolsables. <br /><br />

                        Cambios, Reembolsos y Cancelaciones de boletos. La solicitud de compra de los boletos que se realiza a través del sistema Full Pass es una oferta vinculante y una vez recibida la autorización del banco emisor de la tarjeta de crédito o débito correspondiente o del banco aceptante, la operación de compra será definitiva y no estará sujeta a cambios, reembolsos, devoluciones o cancelaciones. <br /><br />

                        Politicas de cancelación y posposición de eventos. La posposición o cancelación del evento es responsabilidad exclusiva del organizador y en su caso por orden de la autoridad competente, liberando a Full Pass de cualquier responsabilidad. <br /><br />

                        Para el caso de eventos cancelados por causas ajenas a Full Pass el reembolso se hará únicamente por el precio del boleto, sin incluir cargos por servicio. Derivado del marco legal y de la situación por la que atraviesa el país desde marzo de 2020, frente a la pandemia del coronavirus Covid-19 y en seguimiento a las recomendaciones de la Organización Mundial de la Salud (OMS), así como a los Acuerdos adoptados por el gobierno de México, de observancia obligatoria, se ordenó la suspensión de actividades culturales, artísticas, deportivas y de entretenimiento, razón por la cual los organizadores de eventos y/o artistas se vieron obligados a posponer o cancelar en definitiva los eventos programados. <br /><br />

                        Es por ello que de conformidad a los términos y condiciones aplicables a la compra que hubiere adquirido sus boletos para espectáculos públicos a celebrarse en 2021/2022, a través del sistema Full Pass y que se hayan cancelado de manera oficial mediante comunicación por parte del organizador del evento, la devolución del costo del boleto será entregada en el lugar, días y horas que Full Pass señale para tal efecto. <br /><br />

                        En caso de haber realizado su compra con tarjeta de crédito o débito por medio de Full Pass, se reembolsará directamente en un período de 90 días naturales, contados a partir de la fecha de cancelación oficial del evento. Los cargos por servicio por entrega de boletos aplican a cada boleto adquirido a través de Full Pass y no son reembolsables. <br /><br />

                        En el caso de las compras efectuadas con tarjetas bancarias, además aplicarán las instrucciones establecidas por las instituciones bancarias. Tratándose de eventos pospuestos, una vez reprogramadas las nuevas fechas, previa autorización de las autoridades, se respetarán los boletos y lugares para los eventos reprogramados. En caso de que la persona no desee asistir a la fecha reprogramada podrá enviar una comunicación electrónica a Full Pass al correo info@fullpass.com.mx solicitando el reembolso, incluyendo el nombre del titular de la tarjeta con se efectuó la compra, número de referencia o folio, adjuntando una fotografía en la que se vea claramente el boleto o los boletos de los cuales se desee el reembolso, donde lo largo de cada boleto se escriba la palabra NULO con marcador negro (indeleble) y romperlo a la mitad sin pasar por el código de barras, deberán ser rotos con las manos. Entrega de Boletos. <br /><br />

                        Las opciones para recoger los boletos son en los Puntos de Venta autorizados, o en taquilla el día del evento. Si no presenta el Cliente los requisitos solicitados no podrán ser entregados los boletos y Full Pass no tendrá ninguna responsabilidad. Al momento de recibir lo boletos se deberá presentar: <br /><br />

                        1. Tu número de folio (proporcionado al finalizar la compra). <br /><br />

                        2. Identificación oficial (Credencial electoral vigente (IFE /INE) o pasaporte vigente). <br /><br />

                        3. La tarjeta de crédito debito con la que se realizó la compra. <br /><br />

                        Sin excepción alguna, únicamente entregaremos boletos al titular de la tarjeta de crédito o débito. Solo podrás realizar compras por Full Pass hasta un día antes del evento, y podrás recoger tus boletos en las taquillas donde se llevará a cabo el evento, desde 3 horas antes y hasta 1 hora antes del evento, después de este tiempo podrán ser cancelados tus boletos sin ninguna responsabilidad para Full Pass. <br /><br />

                        En virtud de lo anterior, el Cliente expresamente reconoce y acepta que no tendrá derecho a cambios, reembolsos, devoluciones o cancelaciones aún en el supuesto de que no recoja o imprima los boletos adquiridos o no haga uso de ellos. Precauciones. Antes de hacer tu compra por favor revisa cuidadosamente el evento, sección, fecha y número de boletos solicitados, ya que una vez realizada ésta no hay cambios, reembolsos ni devoluciones. Es importante que al llegar evento: <br /><br />

                        1. Te dirijas al acceso destinado. <br /><br />

                        2. Debes mostrar tus boletos impresos o desde tu dispositivo móvil para que podamos escanearlos y validemos tu ingreso al espectáculo. <br /><br />

                        En caso de que más de un boleto sea presentado, se negará el acceso al portador sin importar que te acredites como el comprador. Por tal motivo te sugerimos cuidar los archivos enviados, y no distribuirlos para evitar que sean utilizados por cualquier otra persona. <br /><br />

                        Si tienes los boletos de más personas, es importante que todos entren al inmueble al mismo tiempo para que podamos escanear los boletos desde tu dispositivo. Reposición de Boletos. <br /><br />

                        Cuando recibas tus boletos guárdalos en un lugar seguro, lejos del calor, humedad o de la luz solar. Full Pass no realiza reposición de boletos si éstos han sido perdidos, robados o dañados, quedando éstos bajo la responsabilidad del cliente. <br /><br />

                        Politicas de cancelación y posposición de eventos. Full Pass únicamente distribuye boletos de los eventos ofrecidos, por lo que no es responsable del contenido ni de los horarios de estos. Derivado del marco legal y de la situación por la que atraviesa el país desde marzo de 2020, frente a la pandemia del coronavirus Covid-19 y en seguimiento a las recomendaciones de la Organización Mundial de la Salud (OMS), así como a los Acuerdos adoptados por el gobierno de México, de observancia obligatoria, se ordenó la suspensión de actividades culturales, artísticas, deportivas y de entretenimiento, razón por la cual los organizadores de eventos y/o artistas se vieron obligados a posponer o cancelar en definitiva los eventos programados. <br /><br />

                        Es por ello que de conformidad a los términos y condiciones aplicables a la compra que hubiere adquirido sus boletos para espectáculos públicos a celebrarse en 2021/2022, a través del sistema Full Pass y que se hayan cancelado de manera oficial mediante comunicación por parte del organizador del evento, no requieren hacer ningún trámite para obtener el reembolso en caso de haber realizado su compra con tarjeta de crédito o débito por medio de Full Pass, ya que se reembolsará directamente el precio o valor nominal del boleto pagado, en un período de 90 días naturales, contados a partir de la fecha de cancelación oficial del evento. En el caso de las compras efectuadas con tarjetas bancarias, además aplicarán las instrucciones establecidas por las instituciones bancarias. <br /><br />

                        Los cargos por servicio por entrega de boletos aplican a cada boleto adquirido a través de Full Pass y no son reembolsables. <br /><br />

                        Tratándose de eventos pospuestos, una vez reprogramadas las nuevas fechas, previa autorización de las autoridades, se respetarán los boletos y lugares para los eventos reprogramados. <br /><br />

                        En caso de que la persona no desee asistir a la fecha reprogramada podrá enviar una comunicación electrónica a Full Pass al correo info@fullpass.com.mx solicitando el reembolso, incluyendo el nombre del titular de la tarjeta con la que efectuaste la compra, tu número de referencia adjuntando una fotografía en la que se vea claramente el boleto o los boletos de los cuales desees el reembolso, a lo largo de cada boleto debes escribir la palabra NULO con marcador negro (indeleble) debes romperlo a la mitad sin pasar por el código de barras, deberás romperlos con las manos. <br /><br />

                        Responsabilidad sobre el evento. Full Pass es una empresa independiente, contratada únicamente para la venta de boletos, por lo que no se hace responsable de la calidad o contenido del evento, ni de los actos o consecuencias que dicho evento pueda tener. Jurisdicción y Competencia. <br /><br />

                        Cualquier controversia derivada de estos términos y condiciones de compraventa, las partes se someterán expresamente a la jurisdicción y competencia de los tribunales de la ciudad de Cancún, Quintana Roo, haciendo renuncia expresa de cualquier otra jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros. <br /><br />

                    </p>
                </Col>
            </Row>

        </Layout>
    )
}

export default TermsConditions
