import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../style/index.css';
import '../../style/Home/gridInfo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintRoller, faCalendarCheck, faMoneyCheckAlt, faQrcode, faChartPie, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';

const GridInfo = ({ textSource }) => {

    const iconsRows = [
        [faPaintRoller, faQrcode],
        [faCalendarCheck, faChartPie],
        [faMoneyCheckAlt, faUsersCog]
    ]

    const [responsive, setResponsive] = useState(false);
    
    const checkResponsive = () => {
        const width = window.innerWidth;
        if (width < 767) {
            setResponsive(true)
        } else {
            setResponsive(false)
        }
    }

    window.addEventListener("resize", function () {
        checkResponsive();
    });

    useEffect(() => {
        checkResponsive();
    }, []);

    return (
        <div className='gridInfo'>
            <div className='cardsGrid'>
                <h2>{textSource.title}</h2>
            </div>
            <Container className='cardsGrid'>
                {iconsRows.map((icons, rowIdx) => (
                    <Row className='cardsRow' key={rowIdx}>
                        {icons.map((icon, colIdx) => (
                            <Col key={colIdx} xs={12} md={6} className='cardCol'>
                                <Row className='col-row'>
                                    <Col xs={4} md={2} className='col-row-diamond'>
                                        <div className='diamond-bg'>
                                            <FontAwesomeIcon className='cardIcon' icon={icon} size="xl" />
                                        </div>
                                        {(rowIdx !== 2 || (responsive && colIdx !== 1)) ? (<div className='middle-line'></div>) : (<div></div>)}
                                    </Col>
                                    <Col xs={8} md={10} className='col-row-info'>
                                        <h3>{textSource.info[colIdx + (2 * rowIdx)].subtitle}</h3>
                                        <p>{textSource.info[colIdx + (2 * rowIdx)].text}</p>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                ))}
            </Container>
        </div>
    )
}

export default GridInfo