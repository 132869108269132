import React, { useEffect, useRef, useState } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import img0 from '../../resources/Images/INICIO/eventos.jpg'
import img1 from '../../resources/Images/INICIO/beach-clubs.jpg'
import img2 from '../../resources/Images/INICIO/restaurantes.jpg'
import img3 from '../../resources/Images/INICIO/vip.jpg'
import img4 from '../../resources/Images/INICIO/ecommerce.jpg'
import img5 from '../../resources/Images/INICIO/clases.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import '../../style/index.css';
import '../../style/Home/carousel.css';
import '../../style/Home/swiperCarousel.css';

import Swiper from 'swiper';
import { useSwiper } from 'swiper/react';

function mod(n, m) {
    return ((n % m) + m) % m;
}

const SwiperCarousel = ({ textSource }) => {

    const imgs = [img0, img1, img2, img3, img4, img5]

    const swiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const mySwiper = new Swiper(swiperRef.current, {
            centeredSlides: true,
            loop: true,
            slidesPerView: 3,
            spaceBetween: 40,
            navigation: false,
            on: {
                slideChange: () => {
                    setActiveIndex((swiperRef.current.swiper.realIndex));
                },
            },
        });

        return () => {
            mySwiper.destroy();
        };
    }, []);

    const handlePrevClick = () => {
        swiperRef.current.swiper.slidePrev();
    };

    const handleNextClick = () => {
        swiperRef.current.swiper.slideNext();
    };

    const handleSlideClick = (e) => {
        //swiperRef.current.swiper.slideTo(index, 1000, false);
        if (e.target.offsetParent.className.includes('prev')) {
            if (activeIndex === 0) swiperRef.current.swiper.slideTo(2);
            else swiperRef.current.swiper.slidePrev();
        }
        else if (e.target.offsetParent.className.includes('next')) {
            if (activeIndex === 5) swiperRef.current.swiper.slideTo(3);
            else swiperRef.current.swiper.slideNext();
        }
    }

    return (
        <Container className="carousel-item-2 active">
            <img src={imgs[activeIndex]} className="bg-img" alt="..." />
            <Container fluid={true} className='p-0 carousel-caption-new'>
                <Row className='carousel-row'>
                    <Col xs={12} md={12} lg={5} className='carousel-col carousel-col-info'>
                        <div className='caption-new'>
                            <h1>{textSource[activeIndex].title}</h1>
                            <p>{textSource[activeIndex].info}</p>
                            <button type="button" className="carousel-button">{textSource[activeIndex].button}</button>
                        </div>

                    </Col>
                    <Col xs={12} md={12} lg={7} className='carousel-col'>
                        <div className='indicators'>
                            <Row className='indicators-bttns-responsive'>
                                <div onClick={handlePrevClick} className='circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronLeft} size="2x" />
                                </div>
                                <div onClick={handleNextClick} className='circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronRight} size="2x" />
                                </div>
                            </Row>
                            <Row className='indicators-imgs'>

                                <div className="swiper carousel-swiper" ref={swiperRef}>
                                    <div className="swiper-wrapper carousel-swiper-wrapper">
                                        {imgs.map((img, index) => (
                                            <div className="swiper-slide carousel-swiper-slide" onClick={(e) => handleSlideClick(e)}>

                                                <p>{textSource[mod(index, imgs.length)].category}</p>
                                                <img src={imgs[mod(index, imgs.length)]} alt='...' />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Row>
                            <Row className='indicators-bttns'>
                                <div onClick={handlePrevClick} className='circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronLeft} size="2x" />
                                </div>
                                <div onClick={handleNextClick} className='circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronRight} size="2x" />
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default SwiperCarousel
