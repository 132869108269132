import React, { useState } from 'react'
import Layout from '../components/layout'
import "../style/Politics/politicsPr.css";
import '../style/Footer/faq.css';

import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons'


const Faq = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    }

    const accordionItems = [
        { title: 'Si mi evento fue cancelado ¿qué tengo que hacer para solicitar mi reembolso?', content: 'Si tu evento fue cancelado de manera oficial mediante comunicado por parte del organizador del evento y VBC, no requieren hacer ningún trámite para obtener el reembolso en caso de haber realizado su compra con tarjeta de crédito o débito por medio de fullpass.com.mx, ya que se reembolsará directamente el precio o valor nominal del boleto pagado, durante los 90 días naturales siguientes, contados a partir de la fecha de cancelación oficial del evento. \nEn el caso de las compras efectuadas con tarjetas bancarias, además aplicarán las instrucciones establecidas por las instituciones bancarias. Los cargos por servicio aplican a cada boleto o acceso adquirido, así como, los cargos por entrega de boletos o envío de acceso aplican a cada transacción realizada a través de Fullpassy en ningún caso son reembolsables.\nEn caso de que la compra se haya realizado en las taquillas del evento o en Centros Fullpassy presenciales, el reembolso procederá durante los 90 días naturales siguientes a la fecha de cancelación del evento y la persona deberá presentar el boleto en original, sin enmendaduras ni alteraciones, en el mismo lugar donde efectuó la compra. Los cargos por servicio y por entrega de boletos aplican a cada boleto adquirido a través de Fullpass  independientemente de la forma de pago y no son reembolsables.' },
        { title: 'Si mi evento fue pospuesto y ya conozco la nueva fecha, pero no podré asistir ¿puedo solicitar mi reembolso?', content: 'Una vez reprogramadas las nuevas fechas, previa autorización de las autoridades, se respetarán los boletos y lugares para los eventos reprogramados. En caso de que la persona no desee asistir a la fecha reprogramada podrá enviar una comunicación electrónica a Fullpass al correo info@fullpass.com solicitando el reembolso, incluyendo el nombre del titular de la tarjeta con la que efectuaste la compra, tu número de referencia adjuntando una fotografía en la que se vea claramente el boleto o los boletos de los cuales desees el reembolso, a lo largo de cada boleto debes escribir la palabra NULO con marcador negro (indeleble) debes romperlo a la mitad pasando por el código de barras lateral, deberás romperlos con las manos.\n\nEl reembolso se llevará acabo, por el valor nominal del boleto, durante los 90 días naturales siguientes a la recepción de la solicitud y además aplicarán las instrucciones establecidas por las instituciones bancarias en las compras efectuadas con tarjetas bancarias.\nPor su parte, para las compras efectuadas en taquilla o Centros Fullpass presenciales, el reembolso procede dentro de los 90 días naturales de la fecha en que se da a conocer la nueva fecha del evento pospuesto, atendiendo a la normatividad que las autoridades correspondientes de cada uno de los estados de la República Mexicana, y la persona que haya decidido no utilizar sus boletos para las nuevas fechas deberá presentar el boleto en original, sin enmendaduras ni alteraciones, en el lugar donde efectuó la compra. Los cargos por servicio aplican a cada boleto o acceso adquirido, y los cargos por entrega de boletos o envío de acceso aplican a cada transacción realizada a través de Fullpass, independientemente de la forma de pago y no son reembolsables.' },
        { title: '¿Cuántos días tarda en verse reflejado el reembolso en mi estado de cuenta?', content: 'Para compras realizadas vía telefónica o en la página web www.fullpass.com.mx, el reembolso se llevará a cabo por el valor nominal del boleto, dentro de los 90 días naturales siguientes a la recepción de la solicitud y además aplicarán los tiempos e instrucciones establecidas por las instituciones bancarias en las compras efectuadas con tarjetas.' },
        { title: '¿Cómo puedo pagar mis boletos?', content: 'En el Centro Telefónico Fullpass e Internet tu pago lo podrás realizar con los siguientes métodos:\n\nTarjetas de crédito: Visa, MasterCard o American Express.\n\nTarjetas de débito: Citibanamex, Santander o Banorte.\n\nTarjetas virtuales: Citibanamex y BBVA.\n\nNo podrás utilizar tarjetas departamentales, ni tarjetas de Banco Azteca o BanCoppel.\n\nLas tarjetas de crédito emitidas en el extranjero únicamente son aceptadas en Internet seleccionando como método de entrega Will Call Internacional.\n\nLa forma de pago en las taquillas de los Inmuebles depende de cada inmueble, por lo general es únicamente en efectivo.\n\nAprovecha las preventas exclusivas con tarjetas Citibanamex, Super Venta Santander y Venta Anticipada Banorte.' },
        { title: '¿Por qué Fullpass solicita mi dirección, código postal o número telefónico?', content: 'La información es solicitada entre otras razones, para el envío de los boletos adquiridos, responder tus inquietudes por nuestro departamento de atención a clientes, revisar y asegurar el límite de boletos por evento y por persona para prevenir fraudes de tarjetas de crédito. Si tienes más dudas puedes obtener mayor información consultando nuestro Aviso de Privacidad.' },
    ];

    return (
        <Layout>
            <Row>
                <Col className="contentAll"></Col>
                <div className="titleForm">
                    Preguntas Frecuentes
                </div>
                <div className="solid"></div>
            </Row>
            <Row className='justify-content-center'>
                <Col xs={7}>
                    <div className="faq-accordion">
                        <div className="faq-accordion-item" key={0}>
                            <div className="faq-accordion-title" onClick={() => handleClick(0)}>
                                <span style={{ paddingRight: '0.5rem' }}>{0 === activeIndex ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faCheck} />}</span>

                                Si mi evento fue cancelado ¿qué tengo que hacer para solicitar mi reembolso?
                            </div>
                            {0 === activeIndex && <div className="faq-accordion-content" style={{ whiteSpace: 'pre-wrap' }}>
                                Si tu evento fue cancelado de manera oficial mediante comunicado por parte del organizador del evento y VBC, no requieren hacer ningún trámite para obtener el reembolso en caso de haber realizado su compra con tarjeta de crédito o débito por medio de <a href='fullpass.com.mx'>fullpass.com.mx</a>, ya que se reembolsará directamente el precio o valor nominal del boleto pagado, durante los 90 días naturales siguientes, contados a partir de la fecha de cancelación oficial del evento.
                                <br />
                                En el caso de las compras efectuadas con tarjetas bancarias, además aplicarán las instrucciones establecidas por las instituciones bancarias. Los cargos por servicio aplican a cada boleto o acceso adquirido, así como, los cargos por entrega de boletos o envío de acceso aplican a cada transacción realizada a través de Fullpassy en ningún caso son reembolsables.
                                <br />
                                En caso de que la compra se haya realizado en las taquillas del evento o en Centros Fullpassy presenciales, el reembolso procederá durante los 90 días naturales siguientes a la fecha de cancelación del evento y la persona deberá presentar el boleto en original, sin enmendaduras ni alteraciones, en el mismo lugar donde efectuó la compra. Los cargos por servicio y por entrega de boletos aplican a cada boleto adquirido a través de Fullpass  independientemente de la forma de pago y no son reembolsables.
                            </div>}
                        </div>
                        <div className="faq-accordion-item" key={1}>
                            <div className="faq-accordion-title" onClick={() => handleClick(1)}>
                                <span style={{ paddingRight: '0.5rem' }}>{1 === activeIndex ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faCheck} />}</span>

                                Si mi evento fue pospuesto y ya conozco la nueva fecha, pero no podré asistir ¿puedo solicitar mi reembolso?
                            </div>
                            {1 === activeIndex && <div className="faq-accordion-content" style={{ whiteSpace: 'pre-wrap' }}>
                                Una vez reprogramadas las nuevas fechas, previa autorización de las autoridades, se respetarán los boletos y lugares para los eventos reprogramados. En caso de que la persona no desee asistir a la fecha reprogramada podrá enviar una comunicación electrónica a Fullpass al correo <a href="mailto:info@fullpass.com" data-auto-recognition="true">info@fullpass.com</a> solicitando el reembolso, incluyendo el nombre del titular de la tarjeta con la que efectuaste la compra, tu número de referencia adjuntando una fotografía en la que se vea claramente el boleto o los boletos de los cuales desees el reembolso, a lo largo de cada boleto debes escribir la palabra NULO con marcador negro (indeleble) debes romperlo a la mitad pasando por el código de barras lateral, deberás romperlos con las manos.
                                <br />
                                <br />
                                El reembolso se llevará acabo, por el valor nominal del boleto, durante los 90 días naturales siguientes a la recepción de la solicitud y además aplicarán las instrucciones establecidas por las instituciones bancarias en las compras efectuadas con tarjetas bancarias.
                                <br />
                                Por su parte, para las compras efectuadas en taquilla o Centros Fullpass presenciales, el reembolso procede dentro de los 90 días naturales de la fecha en que se da a conocer la nueva fecha del evento pospuesto, atendiendo a la normatividad que las autoridades correspondientes de cada uno de los estados de la República Mexicana, y la persona que haya decidido no utilizar sus boletos para las nuevas fechas deberá presentar el boleto en original, sin enmendaduras ni alteraciones, en el lugar donde efectuó la compra. Los cargos por servicio aplican a cada boleto o acceso adquirido, y los cargos por entrega de boletos o envío de acceso aplican a cada transacción realizada a través de Fullpass, independientemente de la forma de pago y no son reembolsables.
                            </div>}
                        </div>
                        <div className="faq-accordion-item" key={2}>
                            <div className="faq-accordion-title" onClick={() => handleClick(2)}>
                                <span style={{ paddingRight: '0.5rem' }}>{2 === activeIndex ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faCheck} />}</span>

                                ¿Cuántos días tarda en verse reflejado el reembolso en mi estado de cuenta?
                            </div>
                            {2 === activeIndex && <div className="faq-accordion-content" style={{ whiteSpace: 'pre-wrap' }}>
                                Para compras realizadas vía telefónica o en la página web <a href='www.fullpass.com.mx'>www.fullpass.com.mx</a>, el reembolso se llevará a cabo por el valor nominal del boleto, dentro de los 90 días naturales siguientes a la recepción de la solicitud y además aplicarán los tiempos e instrucciones establecidas por las instituciones bancarias en las compras efectuadas con tarjetas.
                            </div>}
                        </div>
                        <div className="faq-accordion-item" key={3}>
                            <div className="faq-accordion-title" onClick={() => handleClick(3)}>
                                <span style={{ paddingRight: '0.5rem' }}>{3 === activeIndex ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faCheck} />}</span>

                                ¿Cómo puedo pagar mis boletos?
                            </div>
                            {3 === activeIndex && <div className="faq-accordion-content" style={{ whiteSpace: 'pre-wrap' }}>
                                En el Centro Telefónico Fullpass e Internet tu pago lo podrás realizar con los siguientes métodos:
                                <br />
                                <br />
                                Tarjetas de crédito: Visa, MasterCard o American Express.
                                <br />
                                <br />
                                Tarjetas de débito: Citibanamex, Santander o Banorte.
                                <br />
                                <br />
                                Tarjetas virtuales: Citibanamex y BBVA.
                                <br />
                                <br />
                                No podrás utilizar tarjetas departamentales, ni tarjetas de Banco Azteca o BanCoppel.
                                <br />
                                <br />
                                Las tarjetas de crédito emitidas en el extranjero únicamente son aceptadas en Internet seleccionando como método de entrega Will Call Internacional.
                                <br />
                                <br />
                                La forma de pago en las taquillas de los Inmuebles depende de cada inmueble, por lo general es únicamente en efectivo.
                                <br />
                                <br />
                                Aprovecha las preventas exclusivas con tarjetas Citibanamex, Super Venta Santander y Venta Anticipada Banorte.
                            </div>}
                        </div>
                        <div className="faq-accordion-item" key={4}>
                            <div className="faq-accordion-title" onClick={() => handleClick(4)}>
                                <span style={{ paddingRight: '0.5rem' }}>{4 === activeIndex ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faCheck} />}</span>

                                ¿Por qué Fullpass solicita mi dirección, código postal o número telefónico?
                            </div>
                            {4 === activeIndex && <div className="faq-accordion-content" style={{ whiteSpace: 'pre-wrap' }}>
                                La información es solicitada entre otras razones, para el envío de los boletos adquiridos, responder tus inquietudes por nuestro departamento de atención a clientes, revisar y asegurar el límite de boletos por evento y por persona para prevenir fraudes de tarjetas de crédito. Si tienes más dudas puedes obtener mayor información consultando nuestro <a href=''>Aviso de Privacidad</a>.
                            </div>}
                        </div>
                    </div>
                </Col>

            </Row>

        </Layout>
    )
}

export default Faq
