import '../../style/index.css';
import '../../style/Home/cardsInfo.css'

import { Link, NavLink, useNavigate } from 'react-router-dom'

import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import "swiper/css";
import "swiper/css/navigation";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ticketImg from '../../resources/Images/INICIO/ticket.jpg'
import connectImg from '../../resources/Images/INICIO/connect.jpg'
import primeImg from '../../resources/Images/INICIO/prime.jpg'
import festImg from '../../resources/Images/INICIO/fest.jpg'

import ticketLogo from '../../resources/Images/LOGOS/DIVISIONES-FULLPASS-02-e1668441223298.jpg'
import connectLogo from '../../resources/Images/LOGOS/logo-connect-e1668441542312.jpg'
import primeLogo from '../../resources/Images/LOGOS/logo-prime-e1669998782453.jpg'
import festLogo from '../../resources/Images/LOGOS/logo-fest.png'



const CardsInfo = ({ textSource }) => {

    const imgs = [ticketImg, connectImg, primeImg, festImg]
    const logos = [ticketLogo, connectLogo, primeLogo, festLogo]
    const links = ['/ticket', '/connect', '/prime', '/fest']

    const [responsive, setResponsive] = useState(false);

    const checkResponsive = () => {
        const width = window.innerWidth;
        if (width < 767) {
            setResponsive(true)
        } else {
            setResponsive(false)
        }
    }

    window.addEventListener("resize", function () {
        checkResponsive();
    });

    useEffect(() => {
        checkResponsive();
    }, []);

    return (
        <Container className='cards-info'>
            {responsive ? (
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {imgs.map((img, index) => (
                        <SwiperSlide>
                            <div className='cards-info-container'>
                                <img className="cards-info-img" src={img} alt='...'/>
                                <div className="card-caption">
                                    <img className='card-logo' src={logos[index]} alt='...' />
                                    <p>{textSource[index].text}</p>
                                    <Link to={links[index]}>
                                        <button type="button" className="cards-info-btn">{textSource[index].button}</button>
                                    </Link>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <Row className='cards-info-row'>
                    {imgs.map((img, index) => (
                        <Col md={6} lg={3} >
                            <div className='cards-info-container'>
                                <img className="cards-info-img" src={img} />
                                <div className="card-caption">
                                    <img className='card-logo' src={logos[index]} alt='...' />
                                    <p>{textSource[index].text}</p>
                                    <Link to={links[index]}>
                                        <button type="button" className="cards-info-btn">{textSource[index].button}</button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
        </Container>
    )
}

export default CardsInfo