import Accordion from "../components/Home/accordion"
import CardWithBackgroundVideo from "../components/Home/cardWithBackgroundVideo"
import ImgCarousel from "../components/Home/carousel"
import GridInfo from "../components/Home/gridInfo"
import Layout from "../components/layout"
import TwoImgsInfo from "../components/Home/twoImgsInfo"
import NewsLetterForm from "../components/Home/newsletterForm"
import backgroundImage from '../resources/Images/INICIO/concierto.jpg'
import CardsInfo from "../components/Home/cardsInfo"

import { useTranslation } from 'react-i18next'
import ResponsiveCarousel from "../components/Home/responsiveCarousel"
import SwiperCarousel from "../components/Home/swiperCarousel"

const Home = () => {

  const [t, i18n] = useTranslation("home")

  return (
    <Layout>
      <div>
        
        {/*<ResponsiveCarousel />*/}
        <SwiperCarousel textSource={t("home.carousel", { returnObjects: true })} />
        <div className="heading-title">
          <h2>{t("home.quote")}</h2>
        </div>
        <CardsInfo textSource={t("home.cardsInfo", { returnObjects: true })} />
        <TwoImgsInfo textSource={t("home.twoImgsInfo", { returnObjects: true })} />
        <GridInfo textSource={t("home.gridInfo", { returnObjects: true })} />
        <NewsLetterForm textSource={t("home.textWindow", { returnObjects: true })} />
      </div>
    </Layout>
  )
}

export default Home