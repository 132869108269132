import "../../style/index.css";
import "../../style/index.css";
import "../../style/Politics/politicsPr.css";
import politics from '../../translations/es/politics-es.json';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const politicsPr = ({ textSource}) => {
    return (
        <div>
<div className="t">
               
                  
               
               <Container fluid>
                  <Row>
                      <Col className="contentAll">
                            <div>
                              <div 
                              className="titleForm">{textSource.titleG}                             
                              </div>
                              <div className="solid"></div>
                              <div className="textFormE">{textSource.subtitle1}</div>
                              <div className="subT">{textSource.title1}</div>
                              <div className="textForm">{textSource.p1}</div>
                              <div className="listForm">{textSource.p2}</div>
                              <div className="textForm">{textSource.p3}</div>
                              <div className="textForm">{textSource.p4}</div>                              
                              <div className="listForm">{textSource.p5}</div>
                              <div className="subT">{textSource.title2}</div>
                              <div className="listForm">{textSource.p6}</div>
                              <div className="listForm">{textSource.p7}</div>
                              <div className="textForm">{textSource.p8}</div>
                              <div className="textForm">{textSource.p9}</div>
                              <div className="textForm">{textSource.p10}</div>
                              <div className="textForm">{textSource.p11}</div>
                              <div className="subT">{textSource.title3}</div>
                              <div className="listForm">{textSource.p12}</div>
                              <div className="listForm">{textSource.p13}</div>
                              <div className="subT">{textSource.title4}</div>
                              <div className="listForm">{textSource.p15}</div>
                              <div className="listForm">{textSource.p16}</div>
                              <div className="listForm">{textSource.p17}</div>
                              <div className="textForm">{textSource.p18}</div>
                              <div className="textForm">{textSource.p19}</div>
                              <div className="textForm">{textSource.p20}</div>
                              <div className="listForm">{textSource.p21}</div>
                              <div className="listForm">{textSource.p22}</div>
                              <div className="listForm">{textSource.a}</div>
                              <div className="textForm">{textSource.b}</div>
                              <div className="textForm">{textSource.c}</div>
                              <div className="textForm">{textSource.d}</div>
                              <div className="textForm">{textSource.e}</div>
                              <div className="textForm">{textSource.f}</div>
                              <div className="subT">{textSource.title5}</div>
                              <div className="listForm">{textSource.p23}</div>
                              <div className="subT">{textSource.title6}</div>
                              <div className="listForm">{textSource.p24}</div>
                              <div className="listForm">{textSource.p25}</div>
                              <div className="subT">{textSource.title7}</div>
                              <div className="listForm">{textSource.p26}</div>
                              <div className="listForm">{textSource.p27}</div>
                              <div className="subT">{textSource.title8}</div>
                              <div className="listForm">{textSource.p28}</div>
                              <div className="listForm">{textSource.p29}</div>
                              <div className="subT">{textSource.title9}</div>
                              <div className="listForm">{textSource.p30}</div>
                              <div className="subT">{textSource.fin}</div>

                            </div>
                      </Col>
                  </Row>
              </Container>
          
         
              
      </div>
        </div>
            

        )
    }
    
export default politicsPr;
