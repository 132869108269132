import { BrowserRouter, Routes, Route, } from 'react-router-dom'
import LoadingScreen from './components/loadingScreen'
import Connect from './pages/connect'
import Contact from './pages/contact'
import Fest from './pages/fest'
import Home from './pages/home'
import Prime from './pages/prime'
import Ticket from './pages/ticket'
import Politics from './pages/politics'
import FadeTransition from './components/fadeTransition'
import Faq from './pages/faq'
import TermsConditions from './pages/termsConditions'

const App = () => {
  return (
    <BrowserRouter>

      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/ticket' element={<Ticket />} />
          <Route path='/connect' element={<Connect />} />
          <Route path='/fest' element={<Fest />} />
          <Route path='/prime' element={<Prime />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/politics' element={<Politics/>}/>
          <Route path='/faq' element={<Faq/>}/>
          <Route path='/terms-and-conditions' element={<TermsConditions/>}/>

      </Routes>
    </BrowserRouter>
  )
}

export default App