import React, { useState } from "react";
import { renderStep } from "../../utils/renderStep.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ticket from "../../translations/es/ticket-es.json";
import "../../style/index.css";
import "../../style/Ticket/ticketEvent.css";
import "../../style/Connect/connectFirst.css";
import { useTranslation } from 'react-i18next';

import imageB from "../../resources/Images/TICKET/section-form-fondo-e1669314733480.jpg";
const TicketEvent = ({ textSource}) => {

   const [t, i18n] = useTranslation("ticket")
   const ticketEventText = t("ticket.ticketEvent", { returnObjects: true }); 
    
  const [counter, setCounter] = useState(0);
  const [steps, setSteps] = useState();



  setTimeout(() => {
    window.addEventListener("load", () => {
      setSteps(document.querySelectorAll(".step-form"));
      console.log("desde windows load")
    });
  }, 3000)

  const nextStep = () => {

    if (counter <= 2) {
      setCounter(counter + 1);
      steps[counter].className = "step-form step-complete";
      steps[counter + 1].className = "step-form step-active";
    }
  };

  const backStep = () => {
    setCounter(counter - 1);
    steps[counter].className = "step-form";
    steps[counter - 1].className = "step-form step-active";
  };

  return (
    
    <div style={{ backgroundImage: `url(${imageB})` }} className="event-img-format">
      
      <div className="form-section">
        <div className="container">
          <h1 className="event-title"> {textSource.title}</h1>
          <div className="card mb-3 bg-lightwhite">
            <div className="card-header bg-transparent pb-3">
              <ul className="step-container">
                <li className="step-form step-active">
                  <span>
                    {counter === 0 ? 1 : <FontAwesomeIcon icon={faCheck} />}
                  </span>
                  
                  {textSource.headerInfGen}
                </li>
                <li className="step-form">
                  <span>
                    {counter < 2 ? 2 : <FontAwesomeIcon icon={faCheck} />}
                  </span>
                  {textSource.headerInfoEvent}
                  
                </li>
                <li className="step-form">
                  <span>
                    {counter < 3 ? 3 : <FontAwesomeIcon icon={faCheck} />}
                  </span>
                  {textSource.headerLoc}
                  
                </li>
                <li className="step-form">
                  <span>
                    {counter < 4 ? 4 : <FontAwesomeIcon icon={faCheck} />}
                  </span>
                  {textSource.headerInfoTickets}
                  
                </li>
              </ul>
            </div>
            <div className="card-body">
              <form className="row justify-content-between g-4">
              
                {renderStep(counter,ticketEventText) }                
                <div className="col-12 justify-content-end d-flex">
                
                  {counter > 0 && (
                    <button
                      type="button"
                      className="btn-steps-form mx-2"
                      onClick={backStep}
                    >
                      {textSource.buttonBack}
                      
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn-steps-form"
                    onClick={nextStep}
                  >
                    {counter === 3 ? (textSource.buttonSend) : 
                    (textSource.buttonNext)
                    }                                        
                    {/* {counter === 3 ? "Terminar" : '${ticket.ticket.ticketEvent.buttonBack}' } */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default TicketEvent;
