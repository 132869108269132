import "../../style/index.css";
import "../../style/Connect/connectFirst.css";
import "../../style/Connect/connectLast.css";

const connectLast = ({textSource}) => {
    return (
        <div>

            <div className="last-padre bord">
                <div class="container-sm bord align-self-center">
                    <div class="row col gy-5 justify-content-md-center">
                        <div class="col col-lg-10 align-self-center bord">
                            <div className="marg-txt format-text-conn last-hijo">{textSource.text}
                                <div>
                                    <button type="button btn-lg" className="btn-txt btn-bc">                                        
                                        {textSource.button1}
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default connectLast;