import "../../style/index.css";
import "../../style/Prime/primePaymentMethods.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faBitcoin, faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';

const primePaymentMethods = ({textSource}) => {
    return (

        <div>
            <section>

                <div class="container bordo container-pm">
                    <div class="row justify-content-center bordo">
                        <div class="col-md-3 order-2 co-1 cont-ic">
                            <div className="justify-content-center">
                                <div class="bordo col-4 col-lg-6  mrg">
                                    <FontAwesomeIcon className='prIconVisa' icon={faCcVisa} size="4x" />
                                    <FontAwesomeIcon className='prIconAmEx  prop-col-int mx-auto' icon={faCcAmex} size="4x" />
                                </div>

                                <div class="bordo col-4 col-lg-6 mrg">
                                    <FontAwesomeIcon className='prIconMaster' icon={faCcMastercard} size="4x" />
                                    <FontAwesomeIcon className='prIconBit  prop-col-int mx-auto' icon={faBitcoin} size="4x" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 bordo order-3 ">
                            <div class="col-sm-6 col-lg-12 bordo mrgn">
                                <div className="txtTitlePay">
                                    <h5>{textSource.title}</h5>
                                </div>
                                <FontAwesomeIcon className="icons" icon={faCircleCheck} />
                                <div className="colorTxtPay">
                                    <h6>{textSource.met1}</h6>
                                </div>
                                <FontAwesomeIcon className="icons" icon={faCircleCheck} />
                                <div className="colorTxtPay">
                                    <h6>{textSource.met2}</h6>
                                </div>
                                <FontAwesomeIcon className="icons" icon={faCircleCheck} />
                                <div className="colorTxtPay">
                                    <h6>{textSource.met3}</h6>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row justify-content-center  sec-cont-pm  d-flex">
                        <div class="col-sm-3 bordo">
                            <FontAwesomeIcon className='prIcon-Footer' icon={faCcVisa} size="4x" />
                        </div>
                        <div class="col-sm-3 bordo">
                            <FontAwesomeIcon className='prIcon-Footer' icon={faCcAmex} size="4x" />
                        </div>
                        <div class="col-sm-3 bordo">
                            <FontAwesomeIcon className='prIcon-Footer' icon={faCcMastercard} size="4x" />
                        </div>
                        <div class="col-sm-3 bordo">
                            <FontAwesomeIcon className='prIcon-Footer' icon={faBitcoin} size="4x" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default primePaymentMethods;