import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
    Collapse,
    Navbar as BtNavbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink as BtNavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
} from 'reactstrap';

import { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import FullpassLogo from '../resources/Images/LOGOS/Fullpass-sinfondo-1024x515-1-1.jpg'
import '../style/index.css';

import { useTranslation } from 'react-i18next'

const Navbar = ({ textSource }) => {

    const pages = ['/', '/ticket', '/fest', '/connect', '/prime']

    const [t, i18n] = useTranslation()

    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    const [responsive, setResponsive] = useState(false);

    const checkResponsive = () => {
        const width = window.innerWidth;
        if (width < 1000) {
            setResponsive(true)
        } else {
            setResponsive(false)
        }
    }

    window.addEventListener("resize", function () {
        checkResponsive();
    });

    useEffect(() => {
        checkResponsive();
    }, []);

    return (

        <div>
            {responsive ? (
                <Container className='mainNav'>
                    <Row className="rowNav">
                        <BtNavbar color="black" dark fixed="top" className='navbar fixed-top'>
                            <NavbarBrand href="/" className="me-auto">
                                <NavLink to='/'>
                                    <img src={FullpassLogo} alt="Fullpass Logo" height={40} />
                                </NavLink>
                            </NavbarBrand>
                            <div className='langLinks navLang'>
                                <NavLink onClick={() => i18n.changeLanguage('es')} className='navLinkLang'>
                                    <span className='themeColorSpan'>ES</span>
                                </NavLink>
                                <span className='langLine'>|</span>
                                <NavLink onClick={() => i18n.changeLanguage('en')} className='navLinkLang'>
                                    <span className='themeColorSpan'>EN</span>
                                </NavLink>
                                <span className='langLine'>|</span>
                                <NavLink onClick={() => i18n.changeLanguage('pt')} className='navLinkLang'>
                                    <span className='themeColorSpan'>PT</span>
                                </NavLink>
                            </div>
                            <Link to='/contact'>
                                <button type="button" className="contactoBtn">{textSource['contact-button']}</button>
                            </Link>
                            <NavbarToggler onClick={toggleNavbar} className="me-2 navbar-border" />
                            <Collapse isOpen={!collapsed} navbar>
                                <Nav navbar>
                                    {pages.map((page, index) => (
                                        <NavItem className='nav-item'>
                                            <NavLink id='navlink' to={page}>
                                                <span>{textSource.navlinks[index]}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {textSource.changeLanguageTag}
                                        </DropdownToggle>
                                        <DropdownMenu dark style={{ backgroundColor: 'black' }}>
                                            <DropdownItem>
                                                <NavLink onClick={() => i18n.changeLanguage('es')} className='navLinkLang'>
                                                    <span className='themeColorSpan'>ES</span>
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink onClick={() => i18n.changeLanguage('en')} className='navLinkLang'>
                                                    <span className='themeColorSpan'>EN</span>
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink onClick={() => i18n.changeLanguage('pt')} className='navLinkLang'>
                                                    <span className='themeColorSpan'>PT</span>
                                                </NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            </Collapse>
                        </BtNavbar>
                    </Row>
                </Container>
            ) : (
                <nav className='navbar fixed-top black '>
                    <Container className='mainNav'>
                        <Row className="rowNav">
                            <Col xs={4} md={3}>
                                <NavLink to='/'>
                                    <img src={FullpassLogo} alt="Fullpass Logo" height={56} />
                                </NavLink>
                            </Col>
                            <Col xs={2} md={6} className="mainNavList" >
                                <NavLink id='navlink' to='/' className='navLinkUnd'>
                                    <span className='themeColorSpan'>{textSource.navlinks[0]}</span>
                                </NavLink>

                                <NavLink id='navlink' to='/ticket' className='navLinkUnd'>
                                    <span className='themeColorSpan'>{textSource.navlinks[1]}</span>
                                </NavLink>

                                <NavLink id='navlink' to='/fest' className='navLinkUnd'>
                                    <span className='themeColorSpan'>{textSource.navlinks[2]}</span>
                                </NavLink>

                                <NavLink id='navlink' to='/connect' className='navLinkUnd'>
                                    <span className='themeColorSpan'>{textSource.navlinks[3]}</span>
                                </NavLink>

                                <NavLink id='navlink' to='/prime' className='navLinkUnd'>
                                    <span className='themeColorSpan'>{textSource.navlinks[4]}</span>
                                </NavLink>
                            </Col >
                            <Col xs={6} md={3} className=" navLang">
                                <div className='langLinks'>
                                    <NavLink onClick={() => i18n.changeLanguage('es')} className='navLinkLang'>
                                        <span className='themeColorSpan'>ES</span>
                                    </NavLink>
                                    <span className='langLine'>|</span>
                                    <NavLink onClick={() => i18n.changeLanguage('en')} className='navLinkLang'>
                                        <span className='themeColorSpan'>EN</span>
                                    </NavLink>
                                    <span className='langLine'>|</span>
                                    <NavLink onClick={() => i18n.changeLanguage('pt')} className='navLinkLang'>
                                        <span className='themeColorSpan'>PT</span>
                                    </NavLink>
                                </div>
                                <Link to='/contact'>
                                    <button type="button" className="contactoBtn">{textSource['contact-button']}</button>
                                </Link>
                            </Col>
                        </Row >
                    </Container >
                </nav >
            )
            }</div>


    )
}

export default Navbar