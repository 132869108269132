import "../../style/index.css";
import "../../style/Prime/primeNeeds.scss";
import beach from "../../resources/Images/PRIME/beach-club.jpg";
import gym from "../../resources/Images/PRIME/gym.jpg";
import ecomm from "../../resources/Images/PRIME/ecommerce.jpg";
import React, { useRef, useState } from "react";


import "animate.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper';

const PrimeNeeds = ({ textSource}) => {


    const images = {
        gym: `url(${gym})`,
        ecomm: `url(${ecomm})`,
        beach: `url(${beach})`,
    };
    const onHandleMouseEnter = e => {
        console.log(e);
        const back = document.querySelector("#main-back")
        console.log(back);
        back.style.backgroundImage = `${images[e.target.id]}`;

    }


    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    }

    return (


        <div>
            <div class="container">
                <div class="row justify-content-center" style={{ marginTop: '50px' }}>
                    <div class="col-sm-12  d-flex align-items-center ">

                        <div class="col-sm-12 align-self-center justify-content-center b mrg-title-txt pos-col-t " >
                            <div className="pr-title">
                                <h2>{textSource.title}</h2>
                            </div>
                            <div className="pr-txt">
                                {textSource.txt}
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="container-sm justify-content-center cont-main-ben">
                <div className="row borde justify-content-center pos-row row-props ">
                    <div class="col-sm-8 borde align-self-center justify-content-center">
                    </div>
                    <div className="col-sm-9 borde principal-back bord-cont-main" id="main-back">
                        <div className="">
                            <div className="row  bordd">
                                <div className="col-sm-4 bordd cont-one cont-new"  >

                                    <div className="container-overlay-dos bord " onMouseEnter={event => onHandleMouseEnter(event)} id="beach">
                                        <div className="overlay-dos justify-content-last ">

                                            <h1 className="title-overlay capa ">
                                                {textSource.cardTitle1}
                                            </h1>
                                            <div className="txt-overlay ">
                                                {textSource.cardText1}
                                            </div>
                                            <div className="">
                                                <button type="button" class="btn-overlay" >{textSource.buttonBen}</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 bord cont-new" >

                                    <div className="container-overlay-dos bordd" onMouseEnter={event => onHandleMouseEnter(event)} id="gym">
                                        <div className="overlay-dos justify-content-last ">

                                            <h1 className="title-overlay capa ">
                                                {textSource.cardTitle2}
                                            </h1>
                                            <div className="txt-overlay ">
                                                {textSource.cardText2}
                                            </div>
                                            <div className="">
                                                <button type="button" class="btn-overlay" >{textSource.buttonBen}</button>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                <div className="col-sm-4  cont-new"  >

                                    <div className="container-overlay-dos " onMouseEnter={event => onHandleMouseEnter(event)} id="ecomm" >
                                        <div className="overlay-dos justify-content-last">

                                            <h1 className="title-overlay capa">
                                                {textSource.cardTitle3}
                                            </h1>
                                            <div className="txt-overlay ">
                                                {textSource.cardText3}
                                            </div>
                                            <div className="">
                                                <button type="button" class="btn-overlay" >{textSource.buttonBen}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            <Swiper
                spaceBetween={1}
                slidesPerView={2}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper-p"
            />

            <Swiper
                spaceBetween={1}
                slidesPerView={2}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper-p"
                id="mySwiper"
                breakpoints={{
                    800: {
                        spaceBetween: 5,
                    },
                }}
            >
                <div className="main-container-p">
                    <SwiperSlide className="z-index=-1 ">
                        <div className="pos-cont">
                            <div className="txt-tit-p">{textSource.cardTitle1}</div>
                            <div className="line-car-p"></div>
                            <div className="txt-pos- txt-beach">{textSource.cardText1}</div>
                            <button type="button" class="btn-overlay-p">
                                {textSource.buttonBen}
                            </button>
                        </div>
                        <img src={beach} className="img-sw-p" alt="beach" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="txt-tit-p-g ">{textSource.cardTitle2}</div>
                        <div className="line-car-p"></div>
                        <div className="txt-pos-p">{textSource.cardText2}</div>
                        <button type="button" class="btn-overlay-p">
                            {textSource.buttonBen}
                        </button>
                        <img src={gym} className="img-sw-p" alt="gym" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="txt-tit-p-t">{textSource.cardTitle3}</div>
                        <div className="line-car-p"></div>
                        <div className="txt-pos-p">{textSource.cardText3}</div>
                        <button type="button" class="btn-overlay-p">
                            {textSource.buttonBen}
                        </button>
                        <img src={ecomm} className="img-sw-p" alt="ecommerce" />
                    </SwiperSlide>
                </div>
            </Swiper>
        </div>
    );
};

export default PrimeNeeds;