import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import '../../style/index.css';
import '../../style/Home/carousel.css';

import img0 from '../../resources/Images/INICIO/eventos.jpg'
import img1 from '../../resources/Images/INICIO/beach-clubs.jpg'
import img2 from '../../resources/Images/INICIO/restaurantes.jpg'
import img3 from '../../resources/Images/INICIO/vip.jpg'
import img4 from '../../resources/Images/INICIO/ecommerce.jpg'
import img5 from '../../resources/Images/INICIO/clases.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { CSSTransition } from 'react-transition-group';

import { useRef, useState } from 'react';

function mod(n, m) {
    return ((n % m) + m) % m;
}


const ImgCarousel = ({ textSource }) => {

    const imgs = [img0, img1, img2, img3, img4, img5]

    const [isImageVisible, setIsImageVisible] = useState(true);

    const handleImageChange = () => {
        setIsImageVisible(!isImageVisible);
    };

    const [preview, setPreview] = useState({
        'prev': imgs.length - 1,
        'curr': 0,
        'next': 1
    })

    const prev = () => {
        try {
            setPreview({
                'prev': mod(preview.curr - 2, imgs.length),
                'curr': mod(preview.curr - 1, imgs.length),
                'next': preview.curr
            })
            handleImageChange()
        } catch (error) {
            console.log(error)
        }
    }

    const next = () => {
        try {
            setPreview({
                'prev': preview.curr,
                'curr': mod(preview.curr + 1, imgs.length),
                'next': mod(preview.curr + 2, imgs.length)
            })
            handleImageChange()
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <Container className="carousel-item-2 active">
            <img src={imgs[preview.curr]} className="bg-img" alt="..." />
            <Container fluid={true} className='p-0 carousel-caption-new'>
                <Row className='carousel-row'>
                    <Col xs={12} md={12} lg={5} className='carousel-col carousel-col-info'>
                        <div className='caption-new'>
                            <h1>{textSource[preview.curr].title}</h1>
                            <p>{textSource[preview.curr].info}</p>
                            <button type="button" className="carousel-button">{textSource[0].button}</button>
                        </div>

                    </Col>
                    <Col xs={12} md={12} lg={7} className='carousel-col'>
                        <div className='indicators'>
                            <Row className='indicators-bttns-responsive'>
                                <div onClick={prev} className='col circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronLeft} size="2x" />
                                </div>
                                <div onClick={next} className='col circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronRight} size="2x" />
                                </div>
                            </Row>
                            <Row className='indicators-imgs'>
                                <Col xs={3} md={4} className='carousel-img-col prev-img-col'>
                                    <div onClick={prev} className='carousel-img-div'>
                                        <p>{textSource[preview.prev].category}</p>

                                        <img src={imgs[preview.prev]} className="carousel-img" alt="..." />
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className='carousel-img-col curr-img-col'>
                                    <div className='carousel-img-div active'>
                                        <p>{textSource[preview.curr].category}</p>
                                        <img src={imgs[preview.curr]} className="carousel-img" alt="..." />
                                    </div>
                                </Col>
                                <Col xs={3} md={4} className='carousel-img-col next-img-col'>
                                    <div onClick={next} className='carousel-img-div'>
                                        <p>{textSource[preview.next].category}</p>
                                        <img src={imgs[preview.next]} className="carousel-img" alt="..." />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='indicators-bttns'>
                                <div onClick={prev} className='col circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronLeft} size="2x" />
                                </div>
                                <div onClick={next} className='col circle-button'>
                                    <FontAwesomeIcon className='circle-button-icon' icon={faChevronRight} size="2x" />
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ImgCarousel