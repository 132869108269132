import '../../style/index.css';
import '../../style/Home/newsletterForm.css'

import { Link } from 'react-router-dom'

const NewsLetterForm = ({ textSource }) => {

    return (
        <div>
            <div className='newsletterForm'>
                <div className='newsletter-container'>
                    <h2 className="card-title">{textSource.title} <strong>{textSource.titleStrong}</strong></h2>
                    <p className="card-text">{textSource.text}</p>
                    <Link to='/contact'>
                        <button type="button" className="newsletter-btn">{textSource.button}</button>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default NewsLetterForm