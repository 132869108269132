import React from 'react'
import Layout from '../components/layout'
import ContactPage from '../components/Contact/contactPage.js'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const [t, i18n] = useTranslation("contact")
  return (
    
      <Layout>
        <ContactPage  textSource={t("contact.cardLeft", { returnObjects: true })} textSourceB={t("contact.form", { returnObjects: true })}/>
      </Layout>
    
  )
}

export default Contact
