import "../../style/index.css";
import "../../style/Prime/primeFirst.css";
import "../../style/Ticket/ticketFirst.css";
import img from "../../resources/Images/PRIME/banner-connect-1-2048x1366.jpg";
import primeLogo from "../../resources/Images/LOGOS/logo-prime-e1669998782453.jpg";

const primeFirst = ({ textSource}) => {
  return (

    <div>
      <div className="container-fluid bg-image-d ">
        <div className="row justify-content-end">
          <div className="col-lg-4 ml-auto gen-pos bord-p">
            <div className="bord">

              <div className="col-6 col-sm-4">
                <div>
                  <img
                    src={primeLogo}
                    alt="Fullpass Logo"
                    height={35}
                    className="posLogo animate__animated animate__fadeInLeft mrg-columns"
                  />
                </div>
              </div>

              <div className="first-hijo justify-content-lg-left fs-1 ticketFirstT mrg-columns">
                {textSource.title}
              </div>

              <div className="first-hijo ticketFirstP ticketFirstP-font">
                {textSource.text}
              </div>

              <div class="container">
                <div class="row justify-content-start">
                  <div class="first-hijo col-12 col-md-4 justify-content-end bord pos-col-btns mrg-btnred">

                    <button type="button btn-lg" className="space-col btnTF1 align-self-center btn btn-danger btn-md mrg-btnred">
                      {textSource.button}
                    </button>
                  </div>
                  <div class="col-1"></div>

                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-6 align-self-center gen-pos-b bord-p">
            <div className="">
              <img src={img} className="first-hijo imgOvT-p" alt=" " height="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default primeFirst;