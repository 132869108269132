import Layout from '../components/layout';
import '../style/index.css';
import PrimeFirst from '../components/Prime/primeFirst';
import PrimeIcons from '../components/Prime/primeIcons';
import PrimeNeeds from '../components/Prime/primeNeeds';
import PrimePaymentMethods from '../components/Prime/primePaymentMethods';
import { useTranslation } from 'react-i18next';


const Prime = () => {
    const [t, i18n] = useTranslation("prime")
    return (
        <Layout>            
            <PrimeFirst textSource={t("prime.primeFirst", { returnObjects: true })}/>
            <PrimeIcons textSource={t("prime.primeIcons", { returnObjects: true })}/>
            <PrimeNeeds textSource={t("prime.primeBenefits", { returnObjects: true })}/>
            <div></div>
            <PrimePaymentMethods textSource={t("prime.primePaymentMethods", { returnObjects: true })}/>
        </Layout>
    )
}

export default Prime