import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../style/index.css';
import '../../style/Home/twoImgsInfo.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import img0 from '../../resources/Images/INICIO/restaurante.jpg'
import img1 from '../../resources/Images/INICIO/concierto.jpg'
import img2 from '../../resources/Images/INICIO/conferencia.jpg'
import img3 from '../../resources/Images/INICIO/gym.jpg'
import img4 from '../../resources/Images/INICIO/beach-club.jpg'


const TwoImgsInfo = ({ textSource }) => {

    return (
        <Container className='twoImgsInfo'>
            <Row className='twoImgsInfo-row'>
                <Col xs={12} md={6} className='info-col'>
                    <h2>{textSource.title}</h2>
                    <p>{textSource.p1}</p>
                    <p>{textSource.p2}</p>
                    <Container>
                        <Row className='ul-row'>
                            <Col className='ul-col' xs={6} md={6}>
                                <ul>
                                    <li>{textSource.wordList1[0]}</li>
                                    <li>{textSource.wordList1[1]}</li>
                                    <li>{textSource.wordList1[2]}</li>
                                    <li>{textSource.wordList1[3]}</li>
                                </ul>
                            </Col>
                            <Col className='ul-col' xs={6} md={6}>
                                <ul>
                                    <li>{textSource.wordList2[0]}</li>
                                    <li>{textSource.wordList2[1]}</li>
                                    <li>{textSource.wordList2[2]}</li>
                                    <li>{textSource.wordList2[3]}</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                </Col>
                <Col xs={12} md={6} >
                    <div className="grid">
                        <div className='item'>
                            <img className="scaleGridImg" src={img0} />
                        </div>
                        <div className='item'>
                            <img className="scaleGridImg" src={img1} />
                        </div>
                        <div className='item'>
                            <img className="scaleGridImg" src={img2} />
                        </div>
                        <div className='item'>
                            <img className="scaleGridImg" src={img3} />
                        </div>
                        <div className='item'>
                            <img className="scaleGridImg" src={img4} />
                        </div>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}

export default TwoImgsInfo