import Layout from '../components/layout';
//import '../style/index.css';
import ConnectFirst from '../components/Connect/connectFirst';
import ConnectLast from '../components/Connect/connectLast';
import ConnectMiddle from '../components/Connect/connectMiddle';
import { useTranslation } from 'react-i18next'

const Connect = () => {
    const [t, i18n] = useTranslation("connect")
    return (
        <Layout>           
            <ConnectFirst textSource={t("connect.connectFirst", { returnObjects: true })}/>           
            <ConnectMiddle textSource={t("connect.connectMiddle", { returnObjects: true })}/>            
            <ConnectLast textSource={t("connect.connectLast", { returnObjects: true })}/>
        </Layout>

    )
}

export default Connect