import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { NavLink, useNavigate } from 'react-router-dom'
import React, { useState } from 'react';
import '../style/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faBitcoin, faCcAmex, faCcMastercard, faCcVisa, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import fullpassExperienceLogo from '../resources/Images/LOGOS/Fullpass-sinfondo-1024x515-1-1.jpg'



const Footer = ({ textSource }) => {

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <footer className="pt-4 my-md-5 pt-md-5 ftr">
            <Row className="ftrRow">
                <Col className='logo-col' xs={6} md={6}>
                    <img src={fullpassExperienceLogo} alt='...' height='40px' />
                </Col>
                <Col className='media-col' xs={6} md={6}>
                    <a href="https://www.facebook.com/Fullpassexperience/">
                        <div className='media-circle'>
                            <FontAwesomeIcon className='filter-icon' icon={faFacebook} />
                        </div>
                    </a>

                    <a href="https://www.instagram.com/fullpassexperience/">
                        <div className='media-circle'>
                            <FontAwesomeIcon className='filter-icon' icon={faInstagram} />
                        </div>
                    </a>
                </Col>
            </Row>
            <Row className="ftrRow justify-content-center">
                <Col className='ftr-col' xs={12} md={3} lg={2}>
                    <h2 className='ftrH2'>{textSource.services.title}</h2>
                    <ul className="list-unstyled text-small">
                        <NavLink to='/ticket' className="link-secondary text-decoration-none ftrA">{textSource.services.navlinks[0]}</NavLink><br />
                        <NavLink to='/connect' className="link-secondary text-decoration-none ftrA">{textSource.services.navlinks[1]}</NavLink><br />
                        <NavLink to='/prime' className="link-secondary text-decoration-none ftrA">{textSource.services.navlinks[2]}</NavLink><br />
                    </ul>
                </Col>
                <Col className='ftr-col' xs={12} md={3} lg={2}>
                    <h2 className='ftrH2'>{textSource.platforms.title}</h2>
                    <ul className="list-unstyled text-small">
                        <li className="mb-1"><a className="link-secondary text-decoration-none ftrA" href="#">{textSource.platforms.navlinks[0]}</a></li>
                        <li className="mb-1"><a className="link-secondary text-decoration-none ftrA" href="#">{textSource.platforms.navlinks[1]}</a></li>
                    </ul>
                </Col>
                <Col className='ftr-col' xs={12} md={3} lg={2}>
                    <h2 className='ftrH2'>{textSource.policies.title}</h2>
                    <ul className="list-unstyled text-small">
                        <li className="mb-1"><a className="link-secondary text-decoration-none ftrA" href="/contact">{textSource.policies.navlinks[0]}</a></li>
                        <li className="mb-1"><a className="link-secondary text-decoration-none ftrA" href="/faq">{textSource.policies.navlinks[1]}</a></li>
                        <li className="mb-1"><a className="link-secondary text-decoration-none ftrA" href="/terms-and-conditions">{textSource.policies.navlinks[2]}</a></li>
                        <li  className="mb-1"><a className="link-secondary text-decoration-none ftrA" href="/politics">{textSource.policies.navlinks[3]}</a></li>
                    </ul>
                </Col>
                <Col className='ftr-col' xs={12} md={3} lg={2}>
                    <h2 className='ftrH2'>{textSource.security.title}</h2>
                    <ul className="list-unstyled text-small">
                        <li className="mb-1"><a className="link-secondary text-decoration-none ftrA" href="#">{textSource.security.text}</a></li>
                    </ul>
                </Col>
                <Col className='ftr-col' xs={12} md={12} lg={2}>
                    <h2 className='ftrH2'>{textSource.paymentMethods.title}</h2>
                    <FontAwesomeIcon className='ftrIcon' icon={faCcVisa} />
                    <FontAwesomeIcon className='ftrIcon' icon={faCcAmex} />
                    <FontAwesomeIcon className='ftrIcon' icon={faCcMastercard} />
                    <FontAwesomeIcon className='ftrIcon' icon={faBitcoin} />
                </Col>
            </Row>

            <Row className="trademark">
                <Col xs={9} md={10} lg={11}>
                    <p>{textSource.trademark}</p>
                </Col>
                <Col className='return-top-col' xs={3} md={2} lg={1}>
                    <div onClick={handleClick} className='return-top'>
                        <FontAwesomeIcon className='filter-icon' icon={faChevronUp} />
                    </div>
                </Col>

            </Row>
        </footer>
    )
}

export default Footer

