import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

class LoadingScreen extends Component {
    state = {
        isLoading: true
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 3000);
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? (
                    <div className="loading-screen loaded">
                        <div className='loading-screen-spinners'>
                            <Spinner className='loading-screen-spinner lss-1' animation="grow" />
                        </div>
                        <div className='loading-screen-spinners'>
                            <Spinner className='loading-screen-spinner lss-2' animation="grow" />
                        </div>
                        <div className='loading-screen-spinners'>
                            <Spinner className='loading-screen-spinner lss-3' animation="grow" />
                        </div>
                    </div>
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

export default LoadingScreen;