import Layout from '../components/layout';
import '../style/index.css';
import '../style/index.css';
import TicketFirst from '../components/Ticket/ticketFirst'; 
import TicketBenefits from '../components/Ticket/ticketBenefits';
import TicketEvent from '../components/Ticket/ticketEvent';
import { useTranslation } from 'react-i18next';

const Ticket = () => {
    const [t, i18n] = useTranslation("ticket")        
    return (
        <Layout>            
            <TicketFirst textSource={t("ticket.ticketFirst", { returnObjects: true })} />            
            <TicketBenefits  textSource={t("ticket.ticketBenefits", { returnObjects: true })} />
            <TicketEvent  textSource={t("ticket.ticketEvent", { returnObjects: true })} />            
        </Layout>
        
    )
}

export default Ticket