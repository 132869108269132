import "../../style/index.css";
import "../../style/Connect/connectFirst.css";

import "../../style/Ticket/ticketFirst.css";
import img from "../../resources/Images/CONNECT/banner-connect-2048x1366.jpg";
import connectLogo from "../../resources/Images/LOGOS/logo-connect-e1668441542312.jpg";

const connectFirst = ({textSource}) => {
  return (
    <div>
      <div className="first-padre-connect fondo-blur opacity-connect">
        <div className="fo align-self-center  "> 
          <div className="containper align-self-center ">
            <div className="car mb-3 bg-lightwhit " >
              <div className="fondo-opacity align-self-center  "></div>
              <div className="first-hijo align-self-center  ">
                <div class="container">
                  <div class="row justify-content-md-left">
                    <div class="col-lg-6 bord align-self-center">
                      <div className="bord ">

                        <div class="col-6 col-sm-4">
                          <div>
                            <img
                              src={connectLogo}
                              alt="Fullpass Logo"
                              height={35}
                              className="posLogo animate__animated animate__fadeInLeft mrg-columns "
                            />
                          </div>
                        </div>
                      </div>
                      <div className="first-hijo justify-content-lg-left fs-1 ticketFirstT mrg-columns">
                        {textSource.title}
                      </div>

                      <div className="first-hijo ticketFirstP ticketFirstP-font" >
                        {textSource.text}
                      </div>
                      <div class="container">
                        <div class="row justify-content-start">
                          <div class=" first-hijo col-4 justify-content-end  bord pos-col-btns mrg-btnred">

                            <button type="button btn-lg" className=" space-col btnTF1 align-self-center  btn btn-danger btn-md mrg-btnred">
                              {textSource.button1}
                            </button>
                          </div>
                          <div class="col-1"></div>
                          <div class="col-4 bord first-hijo pos-col-btn2">

                            <button type="button btn-lg" className="space-col btnTF2  btn btn-outline-light   mrg-btnw ">
                              {textSource.button2}
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>                    
                    <div class="col col-lg-6  bord">
                      <div className=" imgOvT">
                        <img src={img} className=" first-hijo  imgOvT" alt=" " height="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connectFirst;