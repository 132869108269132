import Footer from "./footer"
import Navbar from "./navbar"

import { useTranslation } from 'react-i18next'
import LoadingScreen from "./loadingScreen"

const Layout = ({ children }) => {

    const [t, i18n] = useTranslation(['header', 'footer'])

    return (
        <LoadingScreen>
            <Navbar textSource={t('header', { returnObjects: true, ns: 'header' })} />
            <div className="" style={{ margin: 0, padding: 0 }}>{children}</div>
            <Footer textSource={t('footer', { returnObjects: true, ns: 'footer' })} />
        </LoadingScreen>
    )
}

export default Layout