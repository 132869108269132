import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import home_es from "./translations/es/home-es.json"
import header_es from "./translations/es/header-es.json"
import footer_es from "./translations/es/footer-es.json"
import contact_es from "./translations/es/contact-es.json"

import home_en from "./translations/en/home-en.json"
import header_en from "./translations/en/header-en.json"
import footer_en from "./translations/en/footer-en.json"
import contact_en from "./translations/en/contact-en.json"

import home_pt from "./translations/pt/home-pt.json"
import header_pt from "./translations/pt/header-pt.json"
import footer_pt from "./translations/pt/footer-pt.json"
import LoadingScreen from "./components/loadingScreen";
import contact_pt from "./translations/pt/contact-pt.json"

import ticket_es from "./translations/es/ticket-es.json"
import ticket_en from "./translations/en/ticket-en.json"
import ticket_pt from "./translations/pt/ticket-pt.json"

import connect_es from "./translations/es/connect-es.json"
import connect_en from "./translations/en/connect-en.json"
import connect_pt from "./translations/pt/connect-pt.json"

import prime_es from "./translations/es/prime-es.json"
import prime_en from "./translations/en/prime-en.json"
import prime_pt from "./translations/pt/prime-pt.json"

import  politics_es from "./translations/es/politics-es.json"
import  politics_en from "./translations/en/politics-en.json"
import  politics_pt from "./translations/pt/politics-pt.json"

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: {
      header: header_es,
      footer: footer_es,
      home: home_es,
      contact: contact_es,
      ticket:  ticket_es,
      connect: connect_es,
      prime: prime_es,
      politics: politics_es 
    },
    en: {
      header: header_en,
      footer: footer_en,
      home: home_en,
      contact: contact_en,
      ticket:  ticket_en,
      connect: connect_en,
      prime: prime_en,
      politics: politics_en  
    },
    pt: {
      header: header_pt,
      footer: footer_pt,
      home: home_pt,
      contact: contact_pt,
      ticket:  ticket_pt,
      connect: connect_pt,
      prime: prime_pt,
      politics: politics_pt   
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <LoadingScreen>
        <App />
      </LoadingScreen>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();