
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDollarToSlot, faCircleInfo, faCreditCard, faGear, faCoins, faShuffle, faUsers, faPaintBrush, faLaptop, faShieldHalved, faInfo } from '@fortawesome/free-solid-svg-icons';
import "../../style/Ticket/ticketBenefits.scss";
import '../../style/index.css';
import img1 from "../../resources/Images/TICKET/beneficios-finanzas.jpg";
import img2 from "../../resources/Images/TICKET/beneficios-informacion.jpg";
import img3 from "../../resources/Images/TICKET/beneficios-pagos.jpg";
import img4 from "../../resources/Images/TICKET/beneficios-soporte.jpg";
import img5 from "../../resources/Images/TICKET/beneficios-comisiones-768x512.jpg";
import img6 from "../../resources/Images/TICKET/beneficios-transacciones.jpg";
import img7 from "../../resources/Images/TICKET/beneficios-patrocinadores.jpg";
import img8 from "../../resources/Images/TICKET/beneficios-personalizar.jpg";
import img9 from "../../resources/Images/TICKET/beneficios-administracion.jpg";
import img10 from "../../resources/Images/TICKET/beneficios-respaldo.jpg";

import "animate.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import React, { useRef, useState, useEffect } from 'react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper';



const TicketBenefits = ({ textSource}) => {
 


  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  }



  const benefist = [
    {
      key: 1,
      icon: faCircleDollarToSlot,
      title: textSource,      
      img: img1,
      description: textSource,
    },
    {
      key: 2,
      icon: faCircleInfo,
      title: textSource,      
      img: img2,
      description: textSource,
    },
    {
      key: 3,
      icon: faCreditCard,
      title: textSource,      
      img: img3,
      description: textSource,
    },
    {
      key: 4,
      icon: faGear,
      title: textSource,
      img: "/static/media/beneficios-soporte.05d37e3a6fde8e4f2627.jpg",
      description: textSource,
    },
    {
      key: 5,
      icon: faCoins,
      title: textSource,
      img: "/static/media/beneficios-comisiones-768x512.dfea1dca7b8b326335f3.jpg",
      description: textSource,
    },
    {
      key: 6,
      icon: faShuffle,
      title: textSource,      
      img: img6,
      description: textSource,
    },
    {
      key: 7,
      icon: faUsers,
      title: textSource,      
      img: img7,
      description: textSource,
    },
    {
      key: 8,
      icon: faPaintBrush,
      title: textSource,
      img: "/static/media/beneficios-personalizar.c1fa367014735f737e96.jpg",
      description: textSource,
    },
    {
      key: 9,
      icon: faLaptop,
      title: textSource,      
      img: img9,
      description: textSource,
    },
    {
      key: 10,
      icon: faShieldHalved,
      title: textSource,      
      img: img10,
      description: textSource,
    },
  ];

  const expandImage = (event) => {
    const currentId = event.currentTarget.id;
    const getAllImages = document.querySelectorAll(".benefit-img");

    getAllImages.forEach((element) => {
      if (element.id === currentId) {
        element.classList.add("benefit-active");
      } else {
        element.classList.remove("benefit-active");
      }
    });
  };



  return (
    <div className="">
      <div className="text-center benefit-titleH animate__animated animate__slideInDown">{textSource.title}</div>
      <div className="">
        <div className="benefits-section">
          {benefist.map(({ key, title, icon, img, description }) => {
            return (
              <div
                className={`benefit-img ${key === 1 && "benefit-active hideMod"}`}
                id={`benefit-img-${key}`}
                key={key}
                onClick={(event) => expandImage(event)}
              >
                <div className="benefit-backdrop"></div>
                <div className="benefit-vertical">
                  <p className="benefit-title">{title[`cardTtitle${key}`]}</p>
                  <span className="benefit-icon">
                    <FontAwesomeIcon className="iconAccord" icon={icon} />
                  </span>
                </div>
                <img src={img} alt={title[`cardTtitle${key}`]} />
                <div className="benefit-content animate__animated animate__fadeIn animate__delay-1s">
                  <span className="benefit-icon">
                    <FontAwesomeIcon className="iconAccord" icon={icon} />
                  </span>
                  <p className="benefit-title">{title[`cardTtitle${key}`]}</p>
                  <p className="benefit-description ">
                    {description[`textCard${key}`]}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <section className="pos-car g-3">
        <Swiper
          spaceBetween={40}
          slidesPerView={3}

          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          //navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="mySwiper"
          
        >
    
        {/* if (mySwiper.display == mySwiper.display.flex){
          mySwiper.update()
        } */}

          {benefist.map(({ key, title, icon, img, description }) => {
            return (
              // <SwiperSlide>
              //      <img src={img} alt={title[`cardTtitle${key}`]} width="500px"/>
              // </SwiperSlide>
              
              <div className="my-swiper-container" id="">
                <section className="pos-car g-3 main-container" min-width="">
                  <>
                    <Swiper

                    
                      spaceBetween={30}
                      centeredSlides={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                       pagination={{
                         clickable: true,
                       }}
                      navigation={true}
                      modules={[Autoplay,  Navigation]}
                      onAutoplayTimeLeft={onAutoplayTimeLeft}
                      className="mySwiper"
                      //id="mySwiper"
                      //ref={mySwiper}
                    >
                      <div className="main-container">
                        <SwiperSlide className="z-index=-1">

                          <div className="icon-cir">
                            <FontAwesomeIcon className="" s icon={faCircleDollarToSlot}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle1}</div>
                          <div className="txt-pos">{textSource.textCard1}</div>
                          <img src={img1} className="img-sw" />

                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faInfo}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle2}</div>
                          <div className="txt-pos">{textSource.textCard2}</div>
                          <img src={img2} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faCreditCard}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle3}</div>
                          <div className="txt-pos">{textSource.textCard3}</div>
                          <img src={img3} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faGear}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle4}</div>
                          <div className="txt-pos">{textSource.textCard4}</div>
                          <img src={img4} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faCoins}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle5}</div>
                          <div className="txt-pos">{textSource.textCard5}</div>
                          <img src={img5} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faShuffle}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle6}</div>
                          <div className="txt-pos">{textSource.textCard6}</div>
                          <img src={img6} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faUsers}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle7}</div>
                          <div className="txt-pos">{textSource.textCard7}</div>
                          <img src={img7} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faPaintBrush}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle8}</div>
                          <div className="txt-pos">{textSource.textCard8}</div>
                          <img src={img8} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faLaptop}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle9}</div>
                          <div className="txt-pos">{textSource.textCard9}</div>
                          <img src={img9} className="img-sw" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="icon-cir">
                            <FontAwesomeIcon className="" icon={faShieldHalved}></FontAwesomeIcon>
                          </div>
                          <div className="line-car"></div>
                          <div className="txt-tit">{textSource.cardTtitle10}</div>
                          <div className="txt-pos">{textSource.textCard10}</div>
                          <img src={img10} className="img-sw" />
                        </SwiperSlide>

                      </div>


                      {/* <div className="autoplay-progress" slot="container-end">
                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                          <circle cx="24" cy="24" r="20"></circle>
                        </svg>
                        <span ref={progressContent}></span>
                      </div> */}
                    </Swiper>
                  </>
                </section>

              </div>


            )
          }
          )}
        </Swiper>
      </section>
    </div>
  );
};
export default TicketBenefits;
