import "../style/Ticket/ticketEvent.css";
import ticket from "../translations/es/ticket-es.json";


export const renderStep = (counter, ticketEventText) =>  {
 
  const step  =  [ 
    
    {
           
      content: (
        <>
          <h5 className="title-step-form">{ticketEventText.title}</h5>
          <div className="col-lg-6">
            <label for="inputName" className="form-label">
            { ticketEventText.infGenName} *              
            </label>
            <input type="text" className="form-control" id="inputName"></input>
          </div>
          <div className="col-lg-6">
            <label for="inputLastname" className="form-label">
            {ticketEventText.infGenAp} *              
            </label>
            <input
              type="text"
              className="form-control"
              id="inputLastname"
            ></input>
          </div>
          <div className="col-lg-6">
            <label for="inputPhone" className="form-label">
            {ticketEventText.infGenPhone} *              
            </label>
            <input type="text" className="form-control" id="inputPhone"></input>
          </div>
          <div className="col-lg-6">
            <label for="inputEmail" className="form-label">
            {ticketEventText.infGenMail} *                    
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
            ></input>
          </div>
        </>
      ),
    },
    {
      content: (
        <>

          <h5 className="title-step-form">{ticketEventText.headerInfoEvent}</h5>
          <div class="mb-2 col-lg-12">
            <label for="formGroupExampleInput" class="form-label"> {ticketEventText.infEventName}*</label>
            <input type="text" class="form-control" id="formGroupExampleInput" placeholder="" />
          </div>
          <div class="mb-1"></div>
          <div class="row">
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infEventLogo} *</label>
              <input class="form-control" type="file" id="formFile" />
            </div>
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infEventVideo} *</label>
              <input type="text" class="form-control" placeholder="https://" aria-label=" " />
            </div>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">{ticketEventText.infEventDescrip} *</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">{ticketEventText.infEventTipo} *</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Concierto</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <h5 className="title-step-form">{ticketEventText.infImgTitle}</h5>
          <div class="mb-1"></div>
          <div class="row">
            <div class="col-lg-4">
              <label for="formFile" class="form-label">{ticketEventText.infImgHead}*</label>
              <input class="form-control" type="file" id="formFile" ></input>
              <div id="emailHelp" class="form-text">Max. file size: 16 MB.</div>
            </div>
            <div class="col-lg-4">
              <label for="formFile" class="form-label">{ticketEventText.infImgPrin}*</label>
              <input class="form-control" type="file" id="formFile" ></input>
              <div id="emailHelp" class="form-text">Max. file size: 16 MB.</div>
            </div>
            <div class="col-lg-4">
              <label for="formFile" class="form-label">{ticketEventText.infImgMap}*</label>
              <input class="form-control" type="file" id="formFile" ></input>
              <div id="emailHelp" class="form-text">Max. file size: 16 MB.</div>
            </div>
          </div>
          <div class="mb-1"></div>
          <div class="row ">
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infImgSlid} *</label>
              <input class="form-control" type="file" id="formFileMultiple" multiple />
              <div id="emailHelp" class="form-text">Max. file size: 16 MB, Max. files: 6.</div>
              {/* <input class="form-control" type="file" id="formFile" ></input> */}

            </div>
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infImgLogos} *</label>
              <input class="form-control rounded" type="file" id="formFileMultiple" multiple />
              <div id="emailHelp" class="form-text">Max. file size: 16 MB, Max. files: 6.</div>
              {/* <input class="form-control" type="file" id="formFile" ></input> */}
            </div>
          </div>
          <div class="mb-1"></div>
          <h5 className="title-step-form">{ticketEventText.infLinksTitle}</h5>
          <div class="mb-1"></div>

          <div class="row">
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infLinksInsta} *</label>
              <input type="text" class="form-control" placeholder="https://" aria-label=" " />
            </div>
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infLinksTwitter} *</label>
              <input type="text" class="form-control" placeholder="https://" aria-label=" " />
            </div>
          </div>
          <div class="mb-1"></div>

          <div class="row">
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infLinksYouTube} </label>
              <input type="text" class="form-control" placeholder="https://" aria-label=" " />
            </div>
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infLinksFace} </label>
              <input type="text" class="form-control" placeholder="https://" aria-label=" " />
            </div>
          </div>
          <div class="mb-1"></div>

          <div class="row">
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infLinksLinkdin} </label>
              <input type="text" class="form-control" placeholder="https://" aria-label=" " />
            </div>
            <div class="col-lg-6">
              <label for="formFile" class="form-label">{ticketEventText.infLinksPage} </label>
              <input type="text" class="form-control" placeholder="https://" aria-label=" " />
            </div>
          </div>
          <div class="mb-1"></div>


        </>
      ),
    },
    {
      content: (<>
        <h5 className="title-step-form">{ticketEventText.infLocTitle}</h5>

        <div class=" ">
          <div>
            <label for="formGroupExampleInput" class="form-label fs-6"> {ticketEventText.infLocSubTitle}*</label>
          </div>
          <label for="formGroupExampleInput" class="form-label"> {ticketEventText.infLocAdrs}</label>
          <input type="text" class="form-control" id="formGroupExampleInput" placeholder="" />
        </div>
        <div class="mb-1"></div>
        <div class="row">
          <div class="col-lg-6">
            <label for="formFile" class="form-label">{ticketEventText.infLocCity} </label>
            <input type="text" class="form-control" placeholder="" aria-label=" " />
          </div>
          <div class="col-lg-6">
            <label for="formFile" class="form-label">{ticketEventText.infLocEstate} </label>
            <input type="text" class="form-control" placeholder="" aria-label=" " />
          </div>
        </div>
        <div class="mb-1"></div>
        <div class="row">
          <div class="col-lg-6">
            <label for="formFile" class="form-label">{ticketEventText.infLocZip} </label>
            <input type="text" class="form-control" placeholder="" aria-label=" " />
          </div>
          <div class="col-lg-6">
            <label for="formFile" class="form-label">{ticketEventText.infLocCountry} </label>
            <select class="form-select" id="specificSizeSelect">
              <option selected>Choose...</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
        <div class="mb-1"></div>
        <h5 className="title-step-form">{ticketEventText.infDateTitle}</h5>

        <div class="col-lg-12">
          <label for="formFile" class="form-label">{ticketEventText.infDateDate} *</label>
          <input id="startDate" class="form-control" type="date" />
        </div>
        <div class="mb-1"></div>

        <div class="container">
          <div class="row justify-content-start ">

            <div class="col-5 justify-content-start">

              <form class="row gx-1 gy-2 align-items-center">
                <div>
                  <label for="formFile" class="form-label">{ticketEventText.infDateTime} *</label>
                </div>

                <div class="col-sm-2">
                  <label class="visually-hidden" for="specificSizeInputName"></label>
                  <input type="text" class="form-control" id="specificSizeInputName" placeholder="HH" />
                </div>

                <div class="col-sm-2">
                  <input type="text" class="form-control" id="specificSizeInputName" placeholder="MM" />
                </div>

                <div class="col-sm-2">
                  <label class="visually-hidden" for="specificSizeSelect">Preference</label>
                  <select class="form-select" id="specificSizeSelect">
                    <option selected>AM</option>
                    <option value="1">AM</option>
                    <option value="2">PM</option>
                  </select>
                </div>
              </form>
            </div>

            <div class="col-5 justify-content-start">
              <form class="row gx-1 gy-2 align-items-center">
                <div>
                  <label for="formFile" class="form-label">{ticketEventText.infDateTFinish} *</label>
                </div>
                <div class="col-sm-2">
                  <label class="visually-hidden" for="specificSizeInputName"></label>
                  <input type="text" class="form-control" id="specificSizeInputName" placeholder="HH" />
                </div>
                <div class="col-sm-2">
                  <input type="text" class="form-control" id="specificSizeInputName" placeholder="MM" />
                </div>
                <div class="col-sm-2">
                  <label class="visually-hidden" for="specificSizeSelect">Preference</label>
                  <select class="form-select" id="specificSizeSelect">
                    <option selected>AM</option>
                    <option value="1">AM</option>
                    <option value="2">PM</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>


      </>),
    },
    {
      content: (<>
        <h5 className="title-step-form">{ticketEventText.infTicketTitle}</h5>



        <div class="container">
          <div class="row justify-content-start">
            <div class="col-6">
              <div class="mb-1">
                <label for="exampleFormControlTextarea1" class="form-label">{ticketEventText.infTicketInfo}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-1">
                <label for="exampleFormControlTextarea1" class="form-label">{ticketEventText.infTicketTime}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-1"></div>
      
        <div class="container">
          <div class="row justify-content-start">
            <div class="col-6">
              <div class="mb-1">
                <label for="exampleFormControlTextarea1" class="form-label">{ticketEventText.infTicketTables}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-1">
                <label for="exampleFormControlTextarea1" class="form-label">{ticketEventText.infTicketCoupons}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>
        <label for="exampleFormControlTextarea1" class="form-label">{ticketEventText.infTicketRange}</label>
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>




      </>),
    },
  ];
  
  return  step[counter ].content;
};
